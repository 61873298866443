.overlay {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: var(--standardPadding);
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 1000;
}
